
span.file {
    display: block;
}

span.file-supprime>a {
    color: red !important;
    text-decoration: line-through;
}

td {
    min-width: 250px;
}
