.atteste-flex {
  display: flex;
  align-items: flex-end;
}
.atteste-flex > :first-child {
  flex: 1;
  margin-bottom: 0;
}

.mnt {
  vertical-align: -webkit-baseline-middle;
}

.btn {
  margin-left: 1em;
}