
span {
    padding-bottom: 0.3rem;
    padding-right: 1em;
    vertical-align: bottom;
    display: inline-block;
    margin-top: auto;
}

.autres-taxes {
    label {
        width: 100%;
        text-align: left;
    }

    input {
        width: 40em;
    }
}

.fardeau-fiscal-actuel-body>div {
    display: inline-block;
    vertical-align: top;
}
